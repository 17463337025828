/*Variables---*/
/*Mixins*/
.headroom {
/*transition transform 200ms linear*/
  background-color: transparent;
  transition: all 200ms linear;
  z-index: 1;
  position: fixed;
  width: 100%;
}
.headroom--pinned {
  background-color: #000;
  -moz-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}
.headroom--unpinned {
  -moz-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.headroom--top {
  background-color: transparent;
}
/*Site Styles---------------------------------*/
main {
  padding: 90px 0;
}
main section {
  padding: 30px 0;
}
a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a:hover {
  text-decoration: none;
}
section {
  padding: 80px 0;
}
.cover-img {
  overflow: hidden;
  max-height: 450px;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
.info-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
.info-text .aligner {
  width: 80%;
}
.info-text .align-item {
  padding: 15px;
  width: 80%;
}
.info-text .align-item.transparent {
  background-color: rgba(255,255,255,0.85);
}
.navbar-collapse {
  -ms-flex-direction: column;
  flex-direction: column;
}
.top-nav {
  margin-left: auto;
}
.top-nav #menu-top-nav,
.top-nav #menu-top-nav-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: auto;
  list-style: none;
  padding-left: 0;
  margin-bottom: 10px;
}
.top-nav #menu-top-nav li a,
.top-nav #menu-top-nav-1 li a {
  color: rgba(255,255,255,0.5);
  padding: 0.5rem;
}
.top-nav #menu-top-nav li a:hover,
.top-nav #menu-top-nav-1 li a:hover,
.top-nav #menu-top-nav li a:focus,
.top-nav #menu-top-nav-1 li a:focus {
  color: #fff;
}
.navbar-collapse {
  -ms-flex-align: end;
  align-items: flex-end;
}
.top-area .widgitable-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.bottom-area {
  display: none;
}
/*Homepage---------------------------------*/
.home .intro {
  text-align: center;
}
.home .jumbotron {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 100vh;
  border-radius: 0;
  margin-bottom: 0;
  background-image: url("../img/main-bg.jpg");
}
.home .callout {
  background-color: #f1efef;
}
section.callout {
  padding: 0;
}
section.callout.full-bg {
  background-image: url("http://via.placeholder.com/1920x650");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 600px;
  position: relative;
}
section.callout.full-bg .info-text {
  height: 600px;
}
.main-callout {
  height: 100%;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}
.main-callout h1 {
  color: #f9f2ef;
  font-size: 10rem;
  font-weight: 700;
  line-height: 0.8;
  text-transform: uppercase;
  position: absolute;
}
.main-callout h1 span {
  font-size: 3.25rem;
  position: absolute;
  margin-top: -25px;
}
.main-callout p {
  text-align: center;
  font-size: 1.5rem;
}
.multi-callout .multi-callout-intro {
  text-align: center;
  margin-bottom: 2rem;
}
.hex-holder {
  display: flex;
  height: 85vh;
  justify-content: center;
  align-items: center;
}
.grad-radial {
  background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(95,38,15,0) 42%, #e25a24 100%);
}
/*Icon Fonts*/
@font-face {
  font-family: 'icomoon';
  src: url("css/fonts/icomoon.eot?6d9quv");
  src: url("css/fonts/icomoon.eot?6d9quv#iefix") format('embedded-opentype'), url("css/fonts/icomoon.ttf?6d9quv") format('truetype'), url("css/fonts/icomoon.woff?6d9quv") format('woff'), url("css/fonts/icomoon.svg?6d9quv#icomoon") format('svg');
  font-weight: 400;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-youtube:before {
  content: "\e900";
}
.icon-down-arrow:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-linkedin:before {
  content: "\e904";
}
.icon-play-arrow:before {
  content: "\e905";
}
.icon-play-container {
  height: 70px;
  width: 93px;
  display: table;
  margin: auto;
  font-size: 4rem;
  text-align: center;
}
.icon-play-container:link {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.icon-play-container:hover,
.icon-play-container:focus {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.icon-play-container:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
}
.icon-twitter:before {
  content: "\e909";
}
/*Navigation*/
.navbar {
  background-color: transparent;
}
.navbar-nav li:last-child .dropdown-menu {
  right: 0;
  left: auto;
}
.navbar-toggler {
  border: 0;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  outline: 0;
}
.search-box {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.search-box .search-form {
  margin-right: 10px;
}
.navbar-toggler {
  outline: 0;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  cursor: pointer;
}
.navbar-toggler:hover .icon-bar,
.navbar-toggler:focus .icon-bar {
  background-color: $red;
}
.navbar-toggler .icon-bar {
  background-color: #000;
  width: 28px;
  height: 3px;
  display: block;
  margin-bottom: 5px;
}
/*Sidebars-----------------------*/
/*Secondary---------------------------------*/
body .jumbotron {
  border-radius: 0;
}
.secondary .jumbotron {
  height: 450px;
  background-image: url("http://via.placeholder.com/1920x450");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
section.main-title.no-jumbotron {
  padding: 180px 0 0 0;
}
/*Footer*/
footer {
  position: relative;
  background-color: #fff;
}
footer a {
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
footer a:hover,
footer a:focus {
  text-decoration: none;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
footer .footer-top {
  background-color: #4f565d;
  padding: 30px 0;
  border-bottom: 8px solid #343a40;
}
footer .footer-top .footer-menu {
  float: right;
  font-size: 1.2rem;
  margin-top: 155px;
}
footer .footer-top .footer-menu ul {
  list-style-type: none;
  padding-left: 0;
}
footer .footer-top .footer-menu ul li {
  display: inline-block;
  margin-left: 30px;
}
footer .footer-top .footer-menu ul li a {
  color: #343a40;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
footer .footer-top .footer-menu ul li a:hover,
footer .footer-top .footer-menu ul li a:focus {
  color: #007bff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
footer .footer-top .footer-one {
  color: #fff;
  overflow: hidden;
  width: 100%;
}
footer .footer-top .footer-one a.phone {
  font-weight: 900;
  color: #fff;
}
footer .footer-top .footer-one ul {
  color: #fff;
  font-size: 1.125rem;
  float: left;
  list-style-type: none;
  padding: 0 0 10px 0;
}
footer .footer-top .footer-one ul .company {
  font-weight: 700;
}
footer .footer-top .footer-one ul a {
  font-weight: 700;
  color: #343a40;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
footer .footer-top .footer-one ul a:hover,
footer .footer-top .footer-one ul a:focus {
  color: #007bff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
footer .footer-top .footer-one .btn {
  float: left;
  font-size: 0.75rem;
  clear: both;
}
footer .footer-bottom {
  background-color: #343a40;
  color: #fff;
  padding: 12px 0;
}
footer .footer-bottom p {
  font-size: 1.125;
  margin-bottom: 0;
}
footer .footer-bottom .social-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}
footer .footer-bottom .social-container .social {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}
footer .footer-bottom .social-container .social li {
  float: left;
  margin: 0 0 0 10px;
}
footer .footer-bottom .social-container .social li a {
  font-size: 1.75rem;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
footer .footer-bottom .social-container .social li a:hover,
footer .footer-bottom .social-container .social li a:focus {
  color: #007bff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
footer .footer-bottom .social-container .social ul {
  padding-top: 55px;
}
footer .footer-bottom .kerry {
  color: #fff;
}
footer .footer-bottom .links {
  padding-top: 15px;
}
footer .footer-bottom .links ul {
  float: right;
}
footer .footer-bottom .links p {
  float: left;
}
footer .footer-bottom .links li {
  float: left;
  margin: 0 0 0 25px;
}
footer .footer-bottom .links a {
  font-size: 1rem;
}
footer .footer-bottom .copyright {
  margin: 8px 0 0 0;
  font-size: 1rem;
}
footer .multi-line-divder-footer hr {
  margin: 2px 0 0 0;
}
footer .multi-line-divder-footer hr.line-1 {
  border-bottom: 4px solid #343a40;
}
footer .multi-line-divder-footer hr.line-2 {
  border-bottom: 2px solid #343a40;
}
/*Home Page*/
/*secondary*/
section.main-title {
  padding: 40px 0;
}
.secondary .sidebar ul {
  list-style-type: none;
  padding-left: 15px;
  border-left: 1px solid #ced4da;
}
.secondary .sidebar ul li {
  border-top: 1px solid #ced4da;
}
.secondary .sidebar ul li:first-child {
  border-top: 0;
}
.secondary .sidebar ul li a {
  padding: 15px 0;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.secondary .sidebar ul li a:focus,
.secondary .sidebar ul li a:hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/*Utilities*/
/*Responsive-------------------------*/
@media (max-width: 576px) {
  .home .jumbotron {
    height: 350px;
  }
  .home .jumbotron .main-callout {
    height: 360px;
  }
  .multi-callout .card {
    margin-bottom: 100px;
  }
  .main-callout figure {
    width: 90%;
  }
  .main-callout figure h1 {
    font-size: 2.5rem;
  }
  .main-callout figure p {
    font-size: 1rem;
  }
  .cover-img {
    max-height: 300px;
  }
  .secondary .jumbotron {
    height: 300px;
  }
  .secondary .main {
    padding: 30px 0;
  }
}
@media (max-width: 767px) {
  .info-text {
    padding: 30px 15px;
  }
  .secondary article {
    margin-bottom: 30px;
  }
  .secondary .sidebar ul {
    border-left: 0;
    padding-left: 0;
  }
  .secondary .jumbotron {
    margin-bottom: 0;
  }
  section.main-title.no-jumbotron {
    padding: 100px 0 0 0;
  }
  footer .footer-bottom .social-container {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 577px) and (max-width: 767px) {
  .multi-callout .card {
    margin-bottom: 100px;
  }
  .main-callout figure {
    width: 80%;
  }
  .cover-img {
    max-height: 375px;
  }
  .secondary .jumbotron {
    height: 375px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .top-nav {
    margin-left: 0;
  }
  .top-nav #menu-top-nav-1 {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .top-nav #menu-top-nav-1 li a {
    padding: 0.5rem 1rem 0.5rem 0;
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .placeholder {
    border: 1px;
  }
}
@media (max-width: 991px) {
  header .navbar {
    padding-top: 15px;
  }
  header .bottom-area {
    display: block;
  }
  header .bottom-area .widget_search {
    display: none;
  }
  header .bottom-area .widget_nav_menu {
    display: block;
  }
  header .top-area {
    padding-top: 15px;
  }
  header .top-area .widget_nav_menu {
    display: none;
  }
  header .top-nav {
    margin-left: 0;
  }
  header .top-nav #menu-top-nav-1 {
    flex-direction: column;
  }
  header .top-nav #menu-top-nav-1 li a {
    padding: 0.5rem 1rem 0.5rem 0;
    display: block;
  }
}
