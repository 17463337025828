rupture.scale = 0 575px 767px 991px 1200px 1600px
rupture.anti-overlap = 1px

/*Variables---*/
$white = #ffffff
$blue = #007bff
$medium-grey = #4f565d
$dark-grey = #343a40
$off-white = #F9F2EF

$font-stack = 'Open Sans', helvetica, arial, sans-serif
$font-stack-headers = 'Roboto', helvetica, arial, sans-serif

/*Mixins*/
transition-all() 
    -webkit-transition all .3s ease-in-out
    -moz-transition all .3s ease-in-out
    -ms-transition all .3s ease-in-out
    -o-transition all .3s ease-in-out
    transition all .3s ease-in-out
    
background-img($y-position, $x-position)
    background-repeat no-repeat
    background-position $x-position $y-position
    background-size cover
    
    
headline($color, $size, $weight, $line)
    color $color
    font-family $font-stack-headers
    font-size $size
    font-weight $weight
    line-height $line

objectfit($obj-width, $obj-height, $obj-pos)
    object-fit cover
    object-position $obj-pos
    height $obj-height
    width  $obj-width
 
.headroom 
    /*transition transform 200ms linear*/
    background-color transparent
    transition all 200ms linear
    z-index 1;
    position fixed
    width 100%

.headroom--pinned
    background-color black
    -moz-transform translateY(0%)
    -webkit-transform translateY(0%)
    -o-transform translateY(0%)
    -ms-transform translateY(0%)
    transform translateY(0%)

.headroom--unpinned 
    -moz-transform translateY(-100%)
    -webkit-transform translateY(-100%)
    -o-transform translateY(-100%)
    -ms-transform translateY(-100%)
    transform translateY(-100%)
    
.headroom--top
    background-color transparent
    
/*Site Styles---------------------------------*/
main
    padding 90px 0
    section 
        padding 30px 0
        
a
    transition-all()
    &:hover
        text-decoration none
        
section 
    padding 80px 0


.cover-img
    overflow hidden
    max-height 450px
    padding 0
    display -ms-flexbox
    display flex
    -ms-flex-align center
    align-items center
    -ms-flex-pack center
    justify-content center
    -ms-flex-direction column
    flex-direction column

.info-text
    display -ms-flexbox
    display flex
    -ms-flex-align center
    align-items center
    -ms-flex-pack center
    justify-content center
    -ms-flex-direction column
    flex-direction column
    .aligner 
        width 80%
    .align-item
        padding 15px
        width 80%
        &.transparent
            background-color hsla(360, 100%, 100%, 0.85)

.navbar-collapse
    -ms-flex-direction column
    flex-direction column
.top-nav
    margin-left auto
    #menu-top-nav, #menu-top-nav-1
        display -ms-flexbox
        display flex
        -ms-flex-direction row
        flex-direction row
        margin-left auto
        list-style none
        padding-left 0
        margin-bottom 10px
        li 
            a 
                color hsla(360, 100%, 100%, 0.5)
                padding .5rem
                &:hover, &:focus
                    color hsla(360, 100%, 100%, 1)
.navbar-collapse
    -ms-flex-align end
    align-items flex-end
.top-area
    .widgitable-area
        display -ms-flexbox
        display flex
        -ms-flex-direction row
        flex-direction row
.bottom-area
    display none
    
/*Homepage---------------------------------*/
.home 
    .intro
        text-align center
    .jumbotron
        background-img(top, center)
        height 100vh
        border-radius 0
        margin-bottom 0
        background-image url('../img/main-bg.jpg')
    .callout
        background-color #f1efef
section.callout
    padding 0
    &.full-bg
        background-image url('http://via.placeholder.com/1920x650')
        background-position center center
        background-size cover
        background-repeat no-repeat
        overflow hidden
        height 600px
        position relative
        .info-text
            height 600px
.main-callout
    height 100%
    width 100%
    display -ms-flexbox
    display flex
    -ms-flex-align center
    align-items center
    -ms-flex-direction column
    flex-direction column
    -ms-flex-pack center
    justify-content center
    h1
        color $off-white
        font-size 10rem
        font-weight 700
        line-height 0.8
        text-transform uppercase
        position absolute
        span  
            font-size 3.25rem
            position absolute
            margin-top -25px
    p 
        text-align center
        font-size 1.5rem
.multi-callout
    .multi-callout-intro
        text-align center
        margin-bottom 2rem
.hex-holder
    display flex
    height 85vh
    justify-content center
    align-items center
    
.grad-radial
    background radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(95,38,15,0) 42%,rgba(226,90,36,1) 100%)
        


/*Icon Fonts*/
@font-face
    font-family 'icomoon'
    src url('css/fonts/icomoon.eot?6d9quv')
    src url('css/fonts/icomoon.eot?6d9quv#iefix') format('embedded-opentype'),
    url('css/fonts/icomoon.ttf?6d9quv') format('truetype'),
    url('css/fonts/icomoon.woff?6d9quv') format('woff'),
    url('css/fonts/icomoon.svg?6d9quv#icomoon') format('svg')
    font-weight normal
    font-style normal

[class^="icon-"], [class*=" icon-"]
    font-family 'icomoon' !important
    speak none
    font-style normal
    font-weight normal
    font-variant normal
    text-transform none
    line-height 1
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale

.icon-youtube
    &:before
        content "\e900"

.icon-down-arrow
    &:before
        content "\e901"

.icon-facebook
    &:before
        content "\e902"

.icon-instagram
    &:before
        content "\e903"

.icon-linkedin
    &:before
        content "\e904"

.icon-play-arrow
    &:before
        content "\e905"


.icon-play-container
    height 70px
    width 93px
    display table
    margin auto
    font-size 4rem
    text-align center
    &:link
        transition-all()
    &:hover, &:focus
        text-decoration none
        transition-all()
    &:before
        content "\e906"

.icon-search
    &:before
        content "\e907"

.icon-twitter
    &:before
        content "\e909" 
        
/*Navigation*/
.navbar
    background-color transparent
.navbar-nav li:last-child .dropdown-menu
    right 0
    left auto
.navbar-toggler
    border 0
    &:hover, &:focus
        outline 0
.search-box
    display -ms-inline-flexbox
    display inline-flex
    .search-form
        margin-right 10px
        
.navbar-toggler 
    outline 0
    &:hover, &:focus
        cursor pointer
        .icon-bar 
            background-color $red
            $transition()
    .icon-bar 
        background-color black
        $transition()
        width 28px
        height 3px
        display block
        margin-bottom 5px

                    
/*Sidebars-----------------------*/

/*Secondary---------------------------------*/
body
    .jumbotron
        border-radius 0
        
.secondary
    .jumbotron
        height 450px
        background-image url('http://via.placeholder.com/1920x450')
        background-img(top, center)

section.main-title.no-jumbotron
    padding 180px 0 0 0

/*Footer*/
footer
    position relative
    background-color $white
    a
        color $white
        transition-all()
        &:hover, &:focus
            text-decoration none
            color $white
            transition-all()
    .footer-top
        background-color $medium-grey
        padding 30px 0
        .footer-menu
            float right
            font-size 1.2rem
            margin-top 155px
            ul 
                list-style-type none
                padding-left 0
                li 
                    display inline-block
                    margin-left 30px
                    a 
                        color $dark-grey
                        transition-all()
                        &:hover, &:focus
                            color $blue
                            transition-all()
        border-bottom 8px solid $dark-grey
        .footer-one
            color $white
            overflow hidden
            width 100%
            a.phone
                font-weight 900
                color $white
            ul
                color $white
                .company
                    font-weight 700
                font-size 1.125rem
                float left
                list-style-type none
                padding 0 0 10px 0
                a 
                    font-weight 700
                    color $dark-grey
                    transition-all()
                    &:hover, &:focus
                        color $blue
                        transition-all()
            .btn
                float left
                font-size 0.75rem
                clear both
    .footer-bottom
        background-color $dark-grey
        color $white
        padding 12px 0
        p
            font-size 1.125
            margin-bottom 0
        .social-container
            display -ms-flexbox
            display flex
            -ms-flex-direction row
            flex-direction row
            -ms-flex-align end
            align-items flex-end
            -ms-flex-pack flex-end
            justify-content flex-end
            .social
                list-style-type none
                margin-bottom 0
                padding-left 0
                li
                    float left
                    margin 0 0 0 10px
                    a 
                        font-size 1.75rem
                        transition-all()
                        &:hover, &:focus
                            color $blue
                            transition-all()
                ul
                    padding-top 55px
        .kerry
            color white
        .links
            padding-top 15px
            ul 
                float right
            p
                float left
            li
                float left
                margin 0 0 0 25px
            a
                font-size 1rem
        .copyright
            margin 8px 0 0 0
            font-size 1rem
    .multi-line-divder-footer
        hr
            margin 2px 0 0 0
            &.line-1
                border-bottom 4px solid $dark-grey

            &.line-2
                border-bottom 2px solid $dark-grey

/*Home Page*/                
        
/*secondary*/
section.main-title
    padding 40px 0
    
.secondary
    .sidebar
        ul 
            list-style-type none
            padding-left 15px
            border-left 1px solid #ced4da
            li 
                border-top 1px solid #ced4da
                &:first-child
                    border-top 0
                a
                    padding 15px 0
                    display block
                    transition-all() 
                    &:focus, &:hover
                        transition-all() 
        
/*Utilities*/

/*Responsive-------------------------*/

@media (max-width: 576px)
    .home 
        .jumbotron
            height 350px
            .main-callout
                height 360px
    .multi-callout
        .card
            margin-bottom 100px
    .main-callout 
        figure
            h1
                font-size 2.5rem
            width 90%
            p
                font-size 1rem
    .cover-img
        max-height 300px
    .secondary 
        .jumbotron
            height 300px
        .main
            padding 30px 0
                
@media (max-width: 767px)
    .info-text
        padding 30px 15px
    .secondary
        article
            margin-bottom 30px
        .sidebar
            ul 
                border-left 0
                padding-left 0
        .jumbotron
            margin-bottom 0
    section.main-title.no-jumbotron
        padding 100px 0 0 0
    footer    
        .footer-bottom
            .social-container
                -ms-flex-align start
                align-items flex-start
                -ms-flex-pack flex-start
                justify-content flex-start
    

@media (min-width: 577px) and (max-width: 767px)
    
    .multi-callout
        .card
            margin-bottom 100px
    .main-callout 
        figure
            width 80%
    .cover-img
        max-height 375px
    .secondary 
        .jumbotron
            height 375px
    
            
@media (min-width: 768px) and (max-width: 991px)
    
    
    .top-nav
        margin-left 0
        #menu-top-nav-1
            -ms-flex-direction column
            flex-direction column
            li 
                a 
                    padding 0.5rem 1rem 0.5rem 0
                    display block
        
@media (min-width: 992px) and (max-width: 1199px)
    .placeholder
        border 1px

@media (max-width: 991px)
    header
        .navbar
            padding-top 15px
        .bottom-area
            display block
            .widget_search
                display none
            .widget_nav_menu
                display block

        .top-area
            padding-top 15px
            .widget_nav_menu
                display none
        .top-nav
            margin-left 0
            #menu-top-nav-1
                flex-direction column
                li 
                    a 
                        padding 0.5rem 1rem 0.5rem 0
                        display block

        
        